import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
export function specialCharactersValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
    //   if (!value || typeof value !== 'string') {
    //     // 如果值为空或不是字符串，返回 null 表示验证通过
    //     return null;
    //   }
  
      const regex = /^[^()*+\[\]\\?${}^|]+$/;
  
      if (!regex.test(value) || value.length < 3 || value.length > 100) {
        // 如果值不符合规则，返回一个包含错误信息的对象
        return { invalidCharacters: true };
      }
  
      // 验证通过，返回 null
      return null;
    };
  }
  export function special64():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      if (!value ) {
        // 如果值为空或不是字符串，返回 null 表示验证通过
        return null;
      }
  
      const regex = /^[^()*+\[\]\\?${}^|]+$/;
  
      if (!regex.test(value) || value.length > 64) {
        // 如果值不符合规则，返回一个包含错误信息的对象
        return { invalidCharacters64: true };
      }
  
      // 验证通过，返回 null
      return null;
    };
  }
  export function email():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      if (!value ) {
        // 如果值为空或不是字符串，返回 null 表示验证通过
        return null;
      }
  
      const regex =  /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  
      if (!regex.test(value) || value.length > 64) {
        // 如果值不符合规则，返回一个包含错误信息的对象
        return { invalidEmail: true };
      }
  
      // 验证通过，返回 null
      return null;
    };
  }
  export function invalidCharacters():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      if (!value ) {
        // 如果值为空或不是字符串，返回 null 表示验证通过
        return null;
      }
  
      const regex = /^[^()*+\[\]\\?${}^|]+$/;
  
      if (!regex.test(value)) {
        // 如果值不符合规则，返回一个包含错误信息的对象
        return { invalidCharacters: true };
      }
  
      // 验证通过，返回 null
      return null;
    };
  }

  export function invalidPoint():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const decimalPattern = /\d$/;
      const valid = decimalPattern.test(control.value);
  
      return valid ? null : { invalidDecimal: true };
     
    };
 
  }

 