export const environment = {
  production: true,
  url: 'zonergy.vidagrid.com',
  // url: 'zonergy-test.vidagrid.com'
  
  projectName:'Zonergy',
  projectNameCH:'兴储世纪',
  inverter_type: {
    'SINGLE_INV_ALL': 1,
    'SINGLE_INV_GRID': 2,
    'THREE_INV_GRID': 3,
    'SINGLE_INV_ALL_1': 4
  }
};
